body {
    background: #121213;
}

.toastText {
    font-weight: bold;
    text-align: center;
}

h2 {
   margin-top: 0px;
}
