.guess-stage-container {
    /*background-color: blue;*/
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.letter-container {
    width: 60px;
    height: 60px;
    background-color: #121213;
    border: 2px solid #3a3a3c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    flex-direction: row;
    margin-right: 4px;
    color: white;
}

.guess-row-container {
    display: flex;
    margin-bottom: 4px;
}
